<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}

</style>

<template>

    <div>

             <!-- Preloader - style you can find in spinners.css -->
    <div class="preloader" style="display: none;">
        <div class="lds-ripple">
            <div class="lds-pos"></div>
            <div class="lds-pos"></div>
        </div>
    </div>             <header class="d-flex" style="background-color: #fff; border-bottom:1px #ededed solid; height:55px;">
        <div class="align-self-center pl-3">
            <router-link to="/">
                <img src="@/assets/img/arrow-back.svg" height="25">
            </router-link>
        </div>
    </header>
    <section class="container-fluid p-0" style="background-color: #fff;">
        <form @submit="login">


        <div class="col-12">
            <div class="row">
            <div class="col-12 col-md-12 col-lg-6 align-self-center mx-auto p-entrar-login-bottom">
                    <div class="col-12 text-center p-entrar-login">
                        <a href="index-cliente.php">
                      <img :src="logo" alt="Logo" style="max-height: 66px;">
             </a>                     <h2 class="pt-4 font-b-7" style="font-size: 18px; color:#7f7f7f">Digite o código de acesso</h2>
                        <p class="pt-1 m-0" style="font-size: 12px; color:#7f7f7f">
                            Insira o código de 6 dígitos que enviamos para o número
                        </p>
                        <h3 class="font-b-7 py-2" style="font-size: 15px; color:#7f7f7f">+{{phone}}</h3>
                    </div>
                    <div class="col-12 col-md-10 col-lg-9 mx-auto pb-4">
                        <!--<form action="" class="needs-validation" novalidate="">-->
                        <div class="col-12">
                            <div class="d-flex mx-auto" style="max-width:max-content">
                                <div class="align-self-center px-1 mx-auto">
                                    <input 
                                        v-model="code.input1"
                                        type="tel" 
                                        required
                                        v class="form-control inputs-login px-0 text-center" 
                                        maxlength="1" value="" style="max-width: 38px; 
                                        height:55px; font-size:22px"
                                    
                                        />
                                </div>
                                <div class="align-self-center px-1 mx-auto">
                                    <input 
                                    v-model="code.input2"
                                        id="input2"
                                        type="tel" 
                                        required
                                        v class="form-control inputs-login px-0 text-center" 
                                        maxlength="1" value="" style="max-width: 38px; 
                                        height:55px; font-size:22px">
                                </div>
                                <div class="align-self-center px-1 mx-auto">
                                    <input 
                                        id="input3"
                                        v-model="code.input3"
                                        type="tel" 
                                        required
                                        v class="form-control inputs-login px-0 text-center" 
                                        maxlength="1" value="" style="max-width: 38px; 
                                        height:55px; font-size:22px">
                                </div>
                                <div class="align-self-center px-1 mx-auto">
                                    <input 
                                        id="input4"
                                        v-model="code.input4"
                                        type="tel" 
                                        required
                                        v class="form-control inputs-login px-0 text-center" 
                                        maxlength="1" value="" style="max-width: 38px; 
                                        height:55px; font-size:22px">
                                </div>
                                <div class="align-self-center px-1 mx-auto">
                                    <input 
                                         id="input5"
                                        v-model="code.input5"
                                        required
                                        type="tel" 
                                        v class="form-control inputs-login px-0 text-center" 
                                        maxlength="1" value="" style="max-width: 38px; 
                                        height:55px; font-size:22px">
                                </div>
                                <div class="align-self-center px-1 mx-auto">
                                    <input 
                                        id="input6"
                                        v-model="code.input6"
                                        type="tel" 
                                        required
                                        v class="form-control inputs-login px-0 text-center" 
                                        maxlength="1" value="" style="max-width: 38px; 
                                        height:55px; font-size:22px">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-center pt-3">
                            <button  id="confirm" type="submit" style="background-color: var(--color-broker)" class="btn btn-info button-login">Confirmar</button>
                        </div>
                        <div class="col-12 text-center pt-3">
                            <span  @click="getCode('CELLPHONE')" title="Senha" class="font-13 a-link-1">Não recebi o código</span>
                        </div>
                        <!--</form>-->
                    </div>
                    
                </div>
            </div>
        </div>
    </form>
    </section>
        <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    </div>
        
    </template>
    <script>
    
    import ThemeService from "@/services/resources/ThemeService";
    import LoginCodeService from "@/services/resources/LoginCodeService";
    import AreaCode  from "@/components/AreaCode.vue";
    import Cookies from "js-cookie";
    const serviceTheme = ThemeService.build();
    const serviceCode = LoginCodeService.build();
    
    
    export default {
        components: {
                AreaCode
            },
        data() {
            return {
                 site:"",
                phoneParams: this.$route.query.phone,
                phone: this.$route.query.phone.split(' ').join(''),
                width: null,
                error: false,
                company_id: null,
                forget_password: window.location.pathname === '/sindico' ? 'LiquidatorRecovery' : 'ClientRecovery',
                logo: null,
                code: {
                    input1: null,
                    input2: null,
                    input3: null,
                    input4: null,
                    input5: null,
                    input6: null  
                },
                ddd: this.$route.query.phone
            };
        },
        methods:{
            getCode(type) {
                let area_code = this.phoneParams.split(' ')[0];
                let ddd = this.phoneParams.split(' ')[1];
                ddd = `${ddd[0]}${ddd[1]}`;
                let cellphone = this.phoneParams.substr(5);

                let data = {
                    company_id: Cookies.get('company_id'),
                    type,
                    cellphone_area_code: area_code,
                    cellphone: '(' + ddd + ')' + ' ' + cellphone
                };

                serviceCode.search(data).then(response => {
                    this.$bvToast.toast('Código reenviado com sucesso', {
                            title: 'Reenviado com sucesso',
                            autoHideDelay: 5000,
                            type: 'success'
                        });

                        this.code.input1 = '';
                        this.code.input2 = '';
                        this.code.input3 = '';
                        this.code.input4 = '';
                        this.code.input5 = '';
                        this.code.input6 = '';
                }).catch(err => {
                    this.$bvToast.toast('Número de telefone inválido', {
                            title: 'Envio de código',
                            autoHideDelay: 5000,
                            type: 'danger   '
                        });

                        this.cellphone.cellphone = '',
                        this.cellphone.ddd = ''

                });
            },
            login(e) {
                
                e.preventDefault();
               let role_id = 6;//DEFAULT BROKER

               let data = {
                    token: `${this.code.input1}${this.code.input2}${this.code.input3}${this.code.input4}${this.code.input5}${this.code.input6}`,
                    role_id
                };

                this.$store
                .dispatch("loginFromCode", data)
                .then(() => {
                })
                .catch((err) => {
                    this.$bvToast.toast('Código de confirmação inválido', {
                        title: 'Confirmação de código',
                        autoHideDelay: 5000,
                        type: 'danger   '
                    });
                    this.error = true;

                    this.code.input1 = '';
                    this.code.input2 = '';
                    this.code.input3 = '';
                    this.code.input4 = '';
                    this.code.input5 = '';
                    this.code.input6 = '';
                });
        
            }, 
            setPhoneAreaCode(value) {
               
               this.$set(this.cellphone, 'cellphone_area_code', value);
           },
            required(image){
                if(!image || image.length == 0){
                    return null;
                }
                if(image.includes('https')){
                    return image;
                }
    
                return require(`@/assets/img/${image}`); 
            },
          
            fetchTheme(){
                let data = {
                    domain: window.location.hostname
                };
    
                serviceTheme
                .search(data)
                .then(resp => {
                    resp.forEach(element => {
                        this.company_id = element.company_id;
                        switch(element.key){
                            case 'PORTAL_BROKER_SENTENCE':
                                this.sentence = element.value;
                                break;
                            case 'PORTAL_CLIENT_COMPONENT_COLOR':
                                this.component_color = element.value;
                                break;
                            case 'PORTAL_CLIENT_BACKGROUND_COLOR':
                                this.background_color = element.value;
                                break;
                            case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                                this.active_register = element.value;
                                break;
                            case 'PORTAL_BROKER_BACKGROUND_DESKTOP':
                                this.desktop_background = `background-image: url("${element.value}")`;
                                break;
                            case 'PORTAL_BROKER_BACKGROUND_MOBILE':
                                this.mobile_background = `background-image: url("${element.value}")`;;
                                break;
                            case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                                this.active_register = element.value;
                                break;
                            case 'SETTINGS_LOGO':
                                this.logo = element.value;
                                break;
                        }
                    });
                });
            },
            
            handleResize() {
          // padrão
                this.width = window.innerWidth;
             },
        },
        computed: {
            isMobile() {
                return this.width <= 768;
            },
        },
        mounted(){
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:4932490,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
             this.site=window.location.host
            window.addEventListener("resize", this.handleResize);
            this.handleResize();
            this.fetchTheme();
        },
        watch: {
            "code.input1":(value) => {
                if(value.length)
                document.getElementById('input2').focus();
            },
            "code.input2":(value) => {
                if(value.length)
                document.getElementById('input3').focus();
            },
            "code.input3":(value) => {
                if(value.length)
                document.getElementById('input4').focus();
            },
            "code.input4":(value) => {
                if(value.length)
                document.getElementById('input5').focus();
            },
            "code.input5":(value) => {
                if(value.length)
                document.getElementById('input6').focus();
            },
            "code.input6":(value) => {
                if(value.length)
                document.getElementById('confirm').focus();
            }
        }
    }
    </script>

<body>
   
    
    </body>